/* eslint-disable vue/require-default-prop */
<template>
  <div class="card mb-2" style="height: 370px;">
    <div
      class="card-header bg-body border-bottom-0 text-uppercase d-flex justify-content-between"
    >
      <div v-if="graph !== 'gf_circulating_supply'">
        <a href="#"
          ><span
            class="badge mx-1"
            :class="currency === 'btc' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeCurrency('btc')"
            >{{ $t("charts.btc") }}</span
          ></a
        >
        <a href="#"
          ><span
            class="badge mx-1"
            :class="currency === 'usd' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeCurrency('usd')"
            >{{ $t("charts.usd") }}</span
          ></a
        >
        <a href="#"
          ><span
            class="badge mx-1"
            :class="currency === 'eur' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeCurrency('eur')"
            >{{ $t("charts.eur") }}</span
          ></a
        >
        <a href="#"
          ><span
            class="badge mx-1"
            :class="currency === 'gbp' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeCurrency('gbp')"
            >{{ $t("charts.gbp") }}</span
          ></a
        >
      </div>
      <div v-else></div>
      <div>
        <a href="#"
          ><span
            class="badge mx-1"
            :class="aggregation === 'd' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeAgg('d')"
          >
            {{ $t("charts.d") }}</span
          ></a
        >
        <a href="#"
          ><span
            class="badge mx-1"
            :class="aggregation === 'w' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeAgg('w')"
          >
            {{ $t("charts.w") }}</span
          ></a
        >
        <a href="#"
          ><span
            class="badge mx-1"
            :class="aggregation === 'm' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeAgg('m')"
          >
            {{ $t("charts.m") }}</span
          ></a
        >
        <a href="#"
          ><span
            class="badge mx-1"
            :class="aggregation === 'y' ? 'bg-primary' : 'bg-body text-body'"
            @click="changeAgg('y')"
            >{{ $t("charts.y") }}</span
          ></a
        >
      </div>
    </div>
    <div
      ref="chartContainer"
      class="card-body p-0"
      style="margin-right: 15px"
    ></div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import {
  createTooltip,
  chartTooltip,
  chartOptions,
  priceSeriesOptions,
  avgLineValue,
  valueFormatter,
} from "@/utils/chart.js";
import { createChart } from "lightweight-charts";

export default defineComponent({
  name: "FinancialStatsChart",
  components: {
  },
  props: {
    frontPage: Boolean,
    graph: String,
    margins: Object,
  },
  setup(props) {
    const store = useStore();
    const aggregation = ref("d");
    const currency = ref("usd");
    const chartContainer = ref();
    const toolTip = ref(createTooltip());

    let series;
    let chart;
    onMounted(() => {
      store.dispatch("charts/fetchFinancialData", {
        aggregation: aggregation.value,
        currency: currency.value,
        graph: props.graph,
      });
      chart = createChart(chartContainer.value, chartOptions);
      chart.applyOptions({
        localization: {
          priceFormatter: valueFormatter(
            props.graph,
            currency.value.toUpperCase()
          ),
        },
      });
      chartContainer.value.appendChild(toolTip.value);
      addSeriesAndData(financialData.value);
      chart.subscribeCrosshairMove(displayTooltip);

      chart.timeScale().fitContent();
    });
    const financialData = computed(() =>
      store.getters["charts/getFinancialData"][props.graph]?.map((item) => {
        const time = Math.floor(Date.parse(item.date) / 1000);
        switch (currency.value) {
          case "btc":
            return { time, value: item[props.graph + "_btc"] };
          case "eur":
            return { time, value: item[props.graph + "_eur"] };
          case "gbp":
            return { time, value: item[props.graph + "_gbp"] };
          case "usd":
            return { time, value: item[props.graph] };
        }
      })
    );
    const avgValue = computed(
      () =>
        financialData.value.reduce((total, next) => total + next.value, 0) /
        financialData.value.length
    );
    const resizeHandler = () => {
      if (!chart || !chartContainer.value) return;
      const dimensions = chartContainer.value.getBoundingClientRect();
      chart.resize(dimensions.width, dimensions.height);
    };
    const displayTooltip = (param) =>
      chartTooltip(
        param,
        chartContainer.value,
        series,
        props.graph,
        toolTip.value,
        "price_charts",
        currency.value
      );
    // Creates the chart series and sets the data.
    const addSeriesAndData = (financialData) => {
      series = chart["addAreaSeries"](priceSeriesOptions);
      if(!financialData) {
          return false
      }
      series.setData(financialData);
      series.createPriceLine(avgLineValue(avgValue.value));
      chart.timeScale().fitContent();
      const newFinancialData = computed(() =>
        financialData.map((item) => {
          return [item.time * 1000, item.value];
        })
      );
      newFinancialData.value.forEach((d) => {
        return d.sort((a, b) => {
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          return 0;
        });
      });
      window.addEventListener("resize", resizeHandler);
    };

    watch(
      () => financialData.value,
      (financialData) => {
        if (series && chart) {
          chart.removeSeries(series);
        }
        chart.applyOptions({
          localization: {
            priceFormatter: valueFormatter(
              props.graph,
              currency.value.toUpperCase()
            ),
          },
        });
        addSeriesAndData(financialData);
      }
    );

    onUnmounted(() => {
      chart.unsubscribeCrosshairMove(displayTooltip);
      if (chart) {
        chart.remove();
        chart = null;
      }
      if (series) {
        series = null;
      }
    });
    watch(aggregation, (aggregation) => {
      store.dispatch("charts/fetchFinancialData", {
        aggregation,
        currency: currency.value,
        graph: props.graph,
      });
    });
    watch(currency, (currency) => {
      store.dispatch("charts/fetchFinancialData", {
        aggregation: aggregation.value,
        currency,
        graph: props.graph,
      });
    });
    watch(
      () => props.graph,
      (graph) => {
        if (graph === "gf_circulating_supply") {
          currency.value = "usd";
        }
        store.dispatch("charts/fetchFinancialData", {
          aggregation: aggregation.value,
          currency: currency.value,
          graph,
        });
      }
    );
    const changeAgg = (agg) => {
      aggregation.value = agg;
    };
    const changeCurrency = (curr) => {
      currency.value = curr;
    };

    const loading = computed(() => store.getters["charts/getLoadingStatus"]);

    return {
      store,
      aggregation,
      currency,
      changeAgg,
      changeCurrency,
      chartContainer,
      loading,
    };
  },
});
</script>

    

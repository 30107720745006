<template>
  <price-stats-skeleton v-if="extendedAssetStats.loading" />
  <price-stats-skeleton v-else-if="extendedAssetStats.error" />
  <template v-else>
    <div class="card mb-2">
      <div class="card-body">
        <span class="badge text-muted ps-3 mb-2 text-uppercase">{{
          $t("price_stats.price_statistics_today")
        }}</span>
        <div class="row small g-2">
          <div class="col-lg-4">
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.market_cap") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{
                  parseInt(extendedAssetStats.data.market_cap).toLocaleString()
                }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-75 py-1 ps-3 text-muted">
                {{ $t("price_stats.market_cap_dominance") }}
              </div>
              <div class="w-25 py-1 ps-3 fw-bold font-monospace text-end">
                {{ extendedAssetStats.data.market_cap_dominance }}%
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.trading_volume") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{
                  parseInt(
                    extendedAssetStats.data.volume_usd_24h
                  ).toLocaleString()
                }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-75 py-1 ps-3 text-muted">
                {{ $t("price_stats.volume_market_cap") }}
              </div>
              <div class="w-25 py-1 ps-3 fw-bold font-monospace text-end">
                {{ extendedAssetStats.data.volume_per_market_cap }}
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.24h_low") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{ extendedAssetStats.data["24h_low"] }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.24h_high") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{ extendedAssetStats.data["24h_high"] }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.7d_low") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{ extendedAssetStats.data["7d_low"] }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.7d_high") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{ extendedAssetStats.data["7d_high"] }}
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.all_time_high") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{ extendedAssetStats.data["all_time_high"] }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.date") }}
              </div>
              <div class="w-100 py-1 ps-3 fw-bold font-monospace text-end">
                {{
                  formatPriceDate(extendedAssetStats.data.all_time_high_date)
                }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.all_time_low") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                ${{ extendedAssetStats.data["all_time_low"] }}
              </div>
            </div>
            <div class="d-flex p-0 border-0 text-decoration-none">
              <div class="w-50 py-1 ps-3 text-muted">
                {{ $t("price_stats.date") }}
              </div>
              <div class="w-50 py-1 ps-3 fw-bold font-monospace text-end">
                {{ formatPriceDate(extendedAssetStats.data.all_time_low_date) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-body py-3">
        <div class="row">
          <div class="col-md-3">
            <span class="text-muted fw-bold mb-0 d-block">
              {{ $t("price_stats.roi") }}</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("price_stats.return_on_investment") }}</span
            >
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-3">
                <div class="text-center">
                  <span
                    class="fw-bold mb-0 d-block"
                    :class="{
                      'text-danger': extendedAssetStats.data.roi_1w < 0,
                      'text-secondary': extendedAssetStats.data.roi_1w === 0,
                      'text-success': extendedAssetStats.data.roi_1w > 0,
                    }"
                    >{{ extendedAssetStats.data.roi_1w }}%</span
                  >
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("price_stats.one_week") }}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="text-center">
                  <span
                    :class="{
                      'text-danger': extendedAssetStats.data.roi_1m < 0,
                      'text-secondary': extendedAssetStats.data.roi_1m === 0,
                      'text-success': extendedAssetStats.data.roi_1m > 0,
                    }"
                    class="fw-bold mb-0 d-block"
                    >{{ extendedAssetStats.data.roi_1m }}%</span
                  >
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("price_stats.one_month") }}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="text-center">
                  <span
                    class="fw-bold mb-0 d-block"
                    :class="{
                      'text-danger': extendedAssetStats.data.roi_3m < 0,
                      'text-secondary': extendedAssetStats.data.roi_3m === 0,
                      'text-success': extendedAssetStats.data.roi_3m > 0,
                    }"
                    >{{ extendedAssetStats.data.roi_3m }}%</span
                  >
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("price_stats.three_months") }}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="text-center">
                  <span
                    class="fw-bold mb-0 d-block"
                    :class="{
                      'text-danger': extendedAssetStats.data.roi_1y < 0,
                      'text-secondary': extendedAssetStats.data.roi_1y === 0,
                      'text-success': extendedAssetStats.data.roi_1y > 0,
                    }"
                    >{{ extendedAssetStats.data.roi_1y }}%</span
                  >
                  <span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >{{ $t("price_stats.one_year") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatPriceDate } from "@/utils/date.js";
import PriceStatsSkeleton from "@/components/skeletons/stats/PriceStatsSkeleton.vue";
export default defineComponent({
  name: "PriceStats",
  components: {
    PriceStatsSkeleton,
  },
  setup() {
    const store = useStore();
    store.dispatch("analytics/fetchExtendedAssetStats");
    const extendedAssetStats = computed(
      () => store.getters["analytics/getExtendedAssetStats"]
    );
    return { extendedAssetStats, formatPriceDate };
  },
});
</script>
<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <price-asset-stats-skeleton v-if="assetStats.loading" />
  <price-asset-stats-skeleton v-else-if="assetStats.error" />
  <div v-else class="card-group text-center mb-2">
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-bitcoin
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="fw-bold mb-0 d-block">
              {{ assetStats.data.price_btc }}</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.bitcoin") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger': assetStats.data.price_btc_change < 0,
              'text-secondary bg-secondary':
                assetStats.data.price_btc_change === 0,
              'text-success bg-success': assetStats.data.price_btc_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill': assetStats.data.price_btc_change < 0,
                'bi-caret-right-fill': assetStats.data.price_btc_change === 0,
                'bi-caret-up-fill': assetStats.data.price_btc_change > 0,
              }"
            ></i>

            {{ assetStats.data.price_btc_change }}%</span
          >
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-dollar
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="fw-bold mb-0 d-block">
              {{ assetStats.data.price_usd }}</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.dollar") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger': assetStats.data.price_usd_change < 0,
              'text-secondary bg-secondary':
                assetStats.data.price_usd_change === 0,
              'text-success bg-success': assetStats.data.price_usd_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill': assetStats.data.price_usd_change < 0,
                'bi-caret-right-fill': assetStats.data.price_usd_change === 0,
                'bi-caret-up-fill': assetStats.data.price_usd_change > 0,
              }"
            ></i>

            {{ assetStats.data.price_usd_change }}%</span
          >
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-euro
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="fw-bold mb-0 d-block">
              {{ assetStats.data.price_eur }}</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.euro") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger': assetStats.data.price_eur_change < 0,
              'text-secondary bg-secondary':
                assetStats.data.price_eur_change === 0,
              'text-success bg-success': assetStats.data.price_eur_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill': assetStats.data.price_eur_change < 0,
                'bi-caret-right-fill': assetStats.data.price_eur_change === 0,
                'bi-caret-up-fill': assetStats.data.price_eur_change > 0,
              }"
            ></i>

            {{ assetStats.data.price_eur_change }}%</span
          >
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-pound
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="fw-bold mb-0 d-block">
              {{ assetStats.data.price_gbp }}</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.pound") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger': assetStats.data.price_gbp_change < 0,
              'text-secondary bg-secondary':
                assetStats.data.price_gbp_change === 0,
              'text-success bg-success': assetStats.data.price_gbp_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill': assetStats.data.price_gbp_change < 0,
                'bi-caret-right-fill': assetStats.data.price_gbp_change === 0,
                'bi-caret-up-fill': assetStats.data.price_gbp_change > 0,
              }"
            ></i>

            {{ assetStats.data.price_gbp_change }}%</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row g-2">
    <div class="col-md-3">
      <div class="card">
        <div class="list-group list-group-flush">
          <router-link
            v-for="(tab, idx) in navTabs"
            :key="idx"
            :to="tab.link"
            :class="`list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center ${chartId == tab.name ? 'active' : ''}`"
            :id="`nav-${tab.name}-tab`"
            data-bs-toggle="tab"
            :data-bs-target="`#nav-${tab.name}`"
            role="tab"
            :aria-controls="`nav-${tab.name}`"
            :aria-selected="!idx ? 'true' : 'false'"
            @click="onTabChange(tab)"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i :class="`bi ${tab.icon}`"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t(`charts.tabs.${tab.name}`) }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <financial-stats-chart :graph="currGraph" />
      <price-stats />
      <div class="row mb-2">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <span class="badge text-muted ps-0 mb-2 text-uppercase">{{
                $t("price_stats.converter")
              }}</span>

              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  v-model="convertInput1"
                  @keyup="changeInput1Price"
                />
                <button
                  class="btn btn-outline-secondary dropdown-toggle w-25"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ startCurrency.toUpperCase() }}
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li v-if="'usd' !== endCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('start', 'usd')"
                      >{{ $t("price_stats.dollar") }}</a
                    >
                  </li>
                  <li v-if="'xlm' !== endCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('start', 'xlm')"
                      >{{ $t("price_stats.stellar") }}</a
                    >
                  </li>
                  <li v-if="'btc' !== endCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('start', 'btc')"
                    >
                      {{ $t("price_stats.bitcoin") }}
                    </a>
                  </li>
                  <li v-if="'eur' !== endCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('start', 'eur')"
                      >{{ $t("price_stats.euro") }}</a
                    >
                  </li>
                  <li v-if="'gbp' !== endCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('start', 'gbp')"
                    >
                      {{ $t("price_stats.pound") }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  v-model="convertInput2"
                  @keyup="changeInput2Price"
                />
                <button
                  class="btn btn-outline-secondary dropdown-toggle w-25"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ endCurrency.toUpperCase() }}
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li v-if="'usd' !== startCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('end', 'usd')"
                      >{{ $t("price_stats.dollar") }}</a
                    >
                  </li>
                  <li v-if="'xlm' !== startCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('end', 'xlm')"
                      >{{ $t("price_stats.stellar") }}</a
                    >
                  </li>
                  <li v-if="'btc' !== startCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('end', 'btc')"
                      >{{ $t("price_stats.bitcoin") }}</a
                    >
                  </li>
                  <li v-if="'eur' !== startCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('end', 'eur')"
                      >{{ $t("price_stats.euro") }}</a
                    >
                  </li>
                  <li v-if="'gbp' !== startCurrency">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="convertCurrency('end', 'gbp')"
                    >{{ $t("price_stats.pound") }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-2 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <span class="badge text-muted ps-0 mb-2 text-uppercase">
                {{ $t("price_stats.price_search") }}
              </span>
              <Datepicker
                v-model="date"
                :enableTimePicker="false"
                :minDate="new Date(2014, 8, 6)"
                :autoApply="true"
                @update:modelValue="searchPrice"
              >
                <template #dp-input="{ value }">
                  <div class="input-group mb-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="dd/mm/yyyy"
                      :value="value"
                      disabled
                    />
                    <span class="input-group-text bg-white"
                      ><i class="bi bi-calendar-event"></i
                    ></span></div
                ></template>
                <template #clear-icon> </template>
              </Datepicker>
              <div class="row mb-1">
                <div class="col-6">
                  <div class="text-center">
                    <span class="text-reset fw-bold mb-0 d-block">{{
                      "$" + priceSearch.avg_price
                    }}</span>
                    <span
                      class="text-muted d-block text-uppercase"
                      style="font-size: 12px"
                    >
                      {{ $t("price_stats.average_price") }}
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-center">
                    <span
                      :class="{
                        'text-danger': priceSearch.roi_today < 0,
                        'text-secondary': priceSearch.roi_today === 0,
                        'text-success': priceSearch.roi_today > 0,
                      }"
                      class="fw-bold mb-0 d-block"
                      >{{ priceSearch.roi_today + "%" }}</span
                    >
                    <span
                      class="text-muted d-block text-uppercase"
                      style="font-size: 12px"
                    >
                      {{ $t("price_stats.roi_if_held_today") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from "vue";
import Search from "@/components/Search.vue";
import { useStore } from "vuex";
import FinancialStatsChart from "@/components/charts/FinancialStatsChart.vue";
import PriceStats from "@/components/stats/PriceStats.vue";
import PriceAssetStatsSkeleton from "@/components/skeletons/stats/PriceAssetStatsSkeleton.vue";
import { formatForSearch } from "@/utils/date.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "PriceCharts",
  components: {
    Search,
    PriceStats,
    PriceAssetStatsSkeleton,
    FinancialStatsChart,
    Datepicker,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const startCurrency = ref("xlm");
    const endCurrency = ref("usd");
    const convertInput1 = ref(5000);
    const convertInput2 = ref(0);
    const date = ref(new Date());
    const priceSearch = ref({ avg_price: "0", roi_today: "0" });
    const currGraph = ref("gf_market_price");

    store.dispatch("analytics/fetchAssetStats");
    store.dispatch("charts/convertPrices");

    const assetStats = computed(() => store.getters["analytics/getAssetStats"]);
    const convertPrices = computed(
      () => store.getters["charts/getConversionPrices"].data
    );

    const onTabChange = (tab) => {
      switch (tab.name) {
        case "market_price":
          currGraph.value = "gf_" + tab.name;
          break;
        case "market_capitalization":
          currGraph.value = "gf_" + tab.name;
          break;
        case "exchange_trade_volume":
          currGraph.value = "gf_" + tab.name;
          break;
        case "circulating_supply":
          currGraph.value = "gf_" + tab.name;
          break;
        case "24h_payments":
          currGraph.value = tab.name;
          break;
        case "24h_dex_trades":
          currGraph.value = tab.name;
          break;
        case "24h_total_fee":
          currGraph.value = tab.name;
          break;
      }
    };
    const navTabs = [
      { name: "market_price", icon: "bi-coin", link: "price" },
      {
        name: "market_capitalization",
        icon: "bi-piggy-bank",
        link: "marketcap",
      },
      {
        name: "exchange_trade_volume",
        icon: "bi-bar-chart-line",
        link: "volume",
      },
      { name: "circulating_supply", icon: "bi-graph-up", link: "circulating" },
      { name: "24h_payments", icon: "bi-graph-up", link: "payments" },
      { name: "24h_dex_trades", icon: "bi-graph-up", link: "trades" },
      { name: "24h_total_fee", icon: "bi-graph-up", link: "total_fee" },
    ];

    const convertCurrency = async (type, curr) => {
      if (type === "start") {
        startCurrency.value = curr;
        changeInput1Price();
      } else {
        endCurrency.value = curr;
        changeInput2Price();
      }
    };

    watch(convertPrices, () => {
      changeInput1Price();
    });

    const changeInput1Price = () => {
      if (convertPrices.value && !isNaN(convertInput1.value)) {
        if (startCurrency.value === "xlm") {
          convertInput2.value =
            (
              convertInput1.value *
              convertPrices.value["xlm_" + endCurrency.value]
            ).toFixed(["btc", "xlm"].includes(endCurrency.value) ? 8 : 4) || "";
        } else if (endCurrency.value === "xlm") {
          convertInput2.value =
            (
              convertInput1.value /
              convertPrices.value["xlm_" + startCurrency.value]
            ).toFixed(["btc", "xlm"].includes(endCurrency.value) ? 8 : 4) || "";
        } else {
          convertInput2.value =
            (
              (convertInput1.value /
                convertPrices.value["xlm_" + startCurrency.value]) *
              convertPrices.value["xlm_" + endCurrency.value]
            ).toFixed(["btc", "xlm"].includes(endCurrency.value) ? 8 : 4) || "";
        }
      }
    };
    const changeInput2Price = () => {
      if (convertPrices.value && !isNaN(convertInput2.value)) {
        if (endCurrency.value === "xlm") {
          convertInput1.value =
            (
              convertInput2.value *
              convertPrices.value["xlm_" + startCurrency.value]
            ).toFixed(["btc", "xlm"].includes(startCurrency.value) ? 8 : 4) ||
            "";
        } else if (startCurrency.value === "xlm") {
          convertInput1.value =
            (
              convertInput2.value /
              convertPrices.value["xlm_" + endCurrency.value]
            ).toFixed(["btc", "xlm"].includes(startCurrency.value) ? 8 : 4) ||
            "";
        } else {
          convertInput1.value =
            (
              (convertInput2.value /
                convertPrices.value["xlm_" + endCurrency.value]) *
              convertPrices.value["xlm_" + startCurrency.value]
            ).toFixed(["btc", "xlm"].includes(startCurrency.value) ? 8 : 4) ||
            "";
        }
      }
    };

    const searchPrice = async () => {
      const res = await store.dispatch(
        "charts/searchPrice",
        formatForSearch(date.value)
      );
      priceSearch.value = res;
    };

    let chartId = 'market_price';
    if (route.params.chartId) {
      chartId = route.params.chartId;
      onTabChange({ name: chartId });
    }

    return {
      navTabs,
      onTabChange,
      assetStats,
      convertInput1,
      convertInput2,
      startCurrency,
      endCurrency,
      date,
      searchPrice,
      priceSearch,
      currGraph,
      chartId,
      convertPrices,
      convertCurrency,
      changeInput1Price,
      changeInput2Price,
    };
  },
});
</script>
<style scoped>
::-ms-clear {
  display: none;
}
input[type="text"]::-webkit-search-decoration,
input[type="text"]::-webkit-search-cancel-button,
input[type="text"]::-webkit-search-results-button,
input[type="text"]::-webkit-search-results-decoration {
  display: none;
}
</style>


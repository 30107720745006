<template>
  <div class="card-group text-center mb-2">
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-bitcoin
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block"> -</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.bitcoin") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-dollar
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block"> -</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.dollar") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-euro
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block"> -</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.euro") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-currency-pound
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block"> -</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("charts.pound") }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "PriceAssetStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>

<style>
</style>